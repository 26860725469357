import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import idIcon from './id.svg';
import enIcon from './en.svg';
import { useState, useEffect } from 'react';
import i18n from '../../i18n';
import { Helmet } from "react-helmet";

const languageAnimation = {
    initial: {
        y: 0,
        opacity: 0,
        height: 'auto',
        // height: 0,
        visibility:"hidden",
        scale: 1,
    },
    animate: {
        y: 0,
        opacity: 1,
        height: 'auto',
        visibility:"visible",
        scale: 1,
    },
    // initial: {
    //     y: -40,
    //     opacity: 0,
    //     height: 0,
    //     scale: 0,
    // },
    // animate: {
    //     y: 0,
    //     opacity: 1,
    //     height: 'auto',
    //     scale: 1,
    // },
}
const ButtonLinkHeader = ({mobileOnly}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [language, setLanguage] = useState(null);
    function handleMouseEnter() {
        setIsHovered(true);
    }
    function handleMouseLeave() {
        setIsHovered(false);
    }
    function setLang(lang){
        setLanguage(lang);
        localStorage.setItem("lang", lang);
        const langs = localStorage.getItem("lang");
        i18n.changeLanguage(langs);
    }
    useEffect(() => {
        setLanguage((localStorage.getItem("lang")) ? localStorage.getItem("lang") : "en");
        i18n.changeLanguage(language);
    }, [language]);
    return (
        <>
            <Helmet htmlAttributes={{ lang: i18n.language }}>
                <meta property="og:locale" content={i18n.language} />
                <meta name="Language" content={i18n.language == 'id' ? 'Indonesia' : 'English'} />
            </Helmet>
            <div className='lang' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {
                    !mobileOnly && 
                    <button className="btn outline">{i18n.language}</button>
                }
                <div className="lang__mbl">
                    {i18n.language} <i className='ms-1 far fa-chevron-down'></i>
                </div>
                <motion.div
                    variants={languageAnimation}
                    initial="initial"
                    animate={isHovered ? "animate" : ""}
                    transition={{
                        ease: 'easeInOut',
                        duration: 0.45,
                    }}
                    className="wrapper__lang"
                >
                    <div className="language-div">
                        <div onClick={() => setLang('id')} className='lang-list'>
                            <img src={idIcon} />
                            Indonesia
                        </div>
                        <div onClick={() => setLang('en')} className='lang-list'>
                            <img src={enIcon} />
                            English
                        </div>
                    </div>
                </motion.div>
            </div>
        </>
    );
}

export default ButtonLinkHeader;