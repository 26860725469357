import { Link, useLocation } from "react-router-dom";
import headImage from '../assets/demo/recipe-image.webp';
import { useEffect, useRef, useState } from "react";
import Ingredients from '../components/Ingredients';
import RecipeSLider from "../components/ListSlider";
import ArrowButton from "../components/utilities/ArrowButton";
import recipes1 from '../assets/demo/recipes-1.webp';
import recipes2 from '../assets/demo/recipes-2.webp';
import { motion } from "framer-motion";
import useFetch from "../customFunction/useFetch"
import i18n from '../i18n';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import bgContent from '../assets/images/recipes/bg-recipecontent.webp';
import bgRelated from '../assets/images/recipes/bg-related.webp';
import { Helmet } from "react-helmet";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";
import NotFound from "../components/NotFound";
const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.45,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}

const RecipeDetail = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const seo = useLocation().pathname.split("/").pop();
    const { data, isPending, error } = useFetch(apiUrl + 'getrecipeDetail?seo=' + seo);
    const currentLang = i18n.language;
    const { t } = useTranslation();
    const sliderRef = useRef();
    const [type, setType] = useState("");
    const [currentId, setCurrentId] = useState("");
    const { data: dataRecomend, isPending: isPendingRecomend, error: errorRecomend } = useFetch(apiUrl + 'getRecipeRecomendation?type=' + type + '&except=' + currentId);
    const [isNotData, setIsNotData] = useState(false);
    useEffect(() => {
        if (data && data.recipeContent[currentLang]) {
            setType(data.recipeContent[currentLang].type);
            setCurrentId(data.recipeContent[currentLang].id);
        }

        if (data && data.recipeContent[currentLang] === null) {
            setIsNotData(true);
        }
    }, [data]);
    const currentLoc = window.location.href;
    return (
        <>
            {
                isNotData && <NotFound />
            }
            {
                data && data.recipeContent[currentLang] &&
                <>
                    <Helmet>
                        <title>PT JINYOUNG - {data.recipeContent[currentLang].name}</title>
                        {/* <meta
                            property="og:image"
                            key="og:image"
                            content={data.recipeContent[currentLang].image_source}
                        /> */}
                        <meta property="og:title" content={'PT JINYOUNG - ' + data.recipeContent[currentLang].name} />
                    </Helmet>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={containerVariants}
                        className='page__wrapper'
                    >
                        <div className='detail__recipe_img'>
                            <img src={data.recipeContent[currentLang].image_source} alt={data.recipeContent[currentLang].name} />
                        </div>
                        <section className="recipes-detail">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-1 order-3 order-lg-1 text-center">
                                        <div className="share-social">
                                            <p className="h-third text-orange text-uppercase text-18px text-spacing-min-0-9px font-bold">Share</p>
                                            <div className="shareButton">
                                                <FacebookShareButton
                                                    url={currentLoc}
                                                    quote={data.recipeContent[currentLang].name}
                                                >
                                                    <FacebookIcon iconFillColor={'#4267B2'} bgStyle={{ "fill": "transparent" }} round={true} size={32} />
                                                </FacebookShareButton>
                                                <LinkedinShareButton
                                                    url={currentLoc}
                                                    quote={data.recipeContent[currentLang].name}
                                                >
                                                    <LinkedinIcon iconFillColor={'#0077b5'} bgStyle={{ "fill": "transparent" }} round={true} size={32} />
                                                </LinkedinShareButton>
                                                <TwitterShareButton
                                                    url={currentLoc}
                                                    quote={data.recipeContent[currentLang].name}
                                                >
                                                    <TwitterIcon iconFillColor={'#1DA1F2'} bgStyle={{ "fill": "transparent" }} round={true} size={32} />
                                                </TwitterShareButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 order-1 mb-5 mb-lg-0 order-lg-2 pe-3 pe-lg-5">
                                        <div className="recipes-detail__desc">
                                            <h1 className='text-30px text-uppercase text-orange font-bold'>{data.recipeContent[currentLang].name}</h1>
                                            <p><i className=" me-2 text-green far fa-clock "></i> {data.recipeContent[currentLang].duration}</p>
                                            <div className="d-lg-none mb-4">
                                                <Ingredients data={data.recipeContent[currentLang].ingredient} />
                                            </div>
                                            <div className="recipe__content">
                                                {parse(data.recipeContent[currentLang].content)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block col-lg-4 order-2 mb-5 order-lg-3">
                                        <Ingredients data={data.recipeContent[currentLang].ingredient} />
                                    </div>
                                </div>
                            </div>
                        </section >
                        <section className="related-recipe">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="title-left-line mb-3 text-uppercase text-orange position-relative"><span>Related Recipes</span></h1>
                                    </div>
                                    <div className="col-4 col-md-6 justify-content-md-end d-flex px-md-4">
                                        {
                                            dataRecomend && dataRecomend.length > 3 &&
                                            <>
                                                <ArrowButton onClick={() => sliderRef.current.handlePrevClick()} className="mx-1 prev" />
                                                <ArrowButton onClick={() => sliderRef.current.handleNextClick()} className="mx-1 next" />
                                            </>
                                        }
                                    </div>
              
                                    {dataRecomend && <RecipeSLider ref={sliderRef} data={dataRecomend.recipeContent[currentLang]} />}   
                                </div>
                            </div>
                        </section>
                    </motion.div >
                </>
            }
        </>
    );
}

export default RecipeDetail;