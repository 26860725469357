import { useState, useEffect } from 'react';
// import datas from './dataProduct';
import './productfilter.scss';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.45,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}

const ProductFilter = ({ datas }) => {
    const [data, setData] = useState(null);
    const [category, setCategory] = useState(null);
    const [item, setItem] = useState(null);
    const [dataItem, setDataItem] = useState(null);
    const [dataCategory, setDataCategory] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('https://lab.gosit.us/jinyoung/static/media/home-about-img.55ee4351f25118ec7593.jpg');

    
    
    const handleItemClick = (event) => {
        if (event.target.tagName === 'IMG') {
            // Get the src attribute of the clicked image
            const imgSrc = event.target.src;
            setCurrentImage(imgSrc);
            setIsOpen(true);
      
            // Add your click event logic here
          }
      };

      const setupEventListeners = () => {
        const elements = document.querySelectorAll('.popup-image');
      
        elements.forEach((element) => {
          element.addEventListener('click', handleItemClick);
        });
      
        return () => {
          elements.forEach((element) => {
            element.removeEventListener('click', handleItemClick);
          });
        };
      };

     
    
      useEffect(() => {
        // Assuming these are defined elsewhere
        if(1){
            setData(datas);
            
            const url = new URL(window.location.href);
            const catValue = url.searchParams.get('cat');
            const idValue = url.searchParams.get('id');
            
            if (catValue) {
                setCategory(Number(catValue));
                currentData(Number(catValue),Number(idValue));
            } else {
                setCategory(datas[0].categoryId);
                currentData(datas[0].categoryId);
            }
            
            if (idValue) {
                setItem(Number(idValue));
            } else {
                setItem(datas[0].categoryProducts[0].id);
            }
        }
        
        
      }, [datas]);



    function handleCategory(id) {
        setCategory(Number(id));
        currentData(Number(id));
    }
    function handleItem(id) {
        setItem(id);
        const dataItems = dataCategory.find((dataItem) => {
            return dataItem.id === id
        })
        setDataItem(dataItems)
    }
    function currentData(id,idProduct) {

        const data = datas.find((data) => {
            return data.categoryId === id
        })
        setDataCategory(data.categoryProducts);

        if(!idProduct){
            idProduct=data.categoryProducts[0].id;
        }

        const dataItems = data.categoryProducts.find((dataItem) => {
            return dataItem.id === idProduct
        })
        setDataItem(dataItems)
        setItem(idProduct);
        setTimeout(()=> {
            const cleanupEventListeners = setupEventListeners();
            return cleanupEventListeners;
        }, 500);
    }

    function selectProduct(event){
        setItem(Number(event.target.value));
        const dataItems = dataCategory.find((dataItem) => {
            return dataItem.id === Number(event.target.value)
        })
        setDataItem(dataItems)
        setTimeout(()=> {
            const cleanupEventListeners = setupEventListeners();
            return cleanupEventListeners;
        }, 500);
    }
    return (
        <>
            {
                data &&
                <>
                    <div className='product__filter'>
                        <div className="product__category ">
                            {data.map((item, index) => (
                                <SelectCategory  className={item.categoryId === category ? "active" : ""} onSetCategory={() => handleCategory(item.categoryId)} key={index} id={item.categoryId} active={category} title={item.categoryName} />
                            ))}
                        </div>
                        <div className="product__item d-none d-md-flex">
                            {dataCategory.map((items, index) => (
                                <SelectCategory className={items.id === item ? "active" : ""} key={index} active={item} id={items.id} onSetItem={() => handleItem(items.id)} title={items.name} />
                            ))}
                        </div>
                        <div className="prod__select d-block d-md-none">
                            <select onChange={selectProduct} value={item}>
                                {dataCategory.map((items, index) => (
                                    <option value={items.id} key={index} >{parse(items.name)}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {
                        parse(dataItem.content)
                    }

            {isOpen && (
                    <Lightbox
                    mainSrc={currentImage}
                    onCloseRequest={() => setIsOpen(false)}
                    />
                )}
                </>
            }
        </>
    );
};

export default ProductFilter;

const SelectCategory = ({ id, title, onSetCategory, onSetItem, active, className }) => {
    return (
        <div className={className}  onClick={() => { onSetCategory && onSetCategory(); onSetItem && onSetItem() }}>{parse(title)}</div>
    )
}