import { useEffect } from 'react';
import { Link,Routes, Route, useLocation, Navigate  } from 'react-router-dom';
import './notfound.scss';
const NotFound = () => {
    const location = useLocation();
    useEffect(() => {
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        header.classList.add('d-none')
        footer.classList.add('d-none')
    });
    return (
        <Routes location={location} key={location.key}>
        <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

export default NotFound;