import parse from "html-react-parser";
import { Helmet } from "react-helmet";
const Seo = ({ data }) => {
    return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>{parse(data.title)}</title>
                <meta name='description' content={data.description} />
                <meta name="keywords" content={data.keyword} />
                <meta name="theme-color" content={data.color ? data.color : '#5AA18E'} />
                <meta name="msapplication-TileColor" content={data.color ? data.color : '#5AA18E'} />
                <meta property="og:type" content={data.type} />
                <meta property="og:title" content={parse(data.title)} />
                <meta property="og:description" content={data.description} />
                <meta property="og:image" key="og:image" content={data.logo} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="" />
                <meta property="og:site_name" content={data.site_name} />
                <meta property="og:image:secure_url" content={data.logo} />
            </Helmet>
    );
}

export default Seo;