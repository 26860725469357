import './productbanner.scss';
import defaultImage1 from './product-default.png';
import defaultImage2 from './product-default-2.png';
import { useTranslation } from 'react-i18next';
// import chef from './chef-joy.webp';
import chef from './chef-joy-product.webp';
const ProductBanner = ({image1, image2}) => {
    const {t} = useTranslation();
    return (
        <div className='product__banner'>
            <div className="container">
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='title-left-line mb-3 text-uppercase text-orange position-relative'><span>{t('product.our_product')}</span></h1>
                        <p className='text-24px font-semibold text-darkgreen text-uppercase text-spacing-min-1-2px'>PT. JINYOUNG serves more than 1000 halal certified products</p>
                    </div>
                </div>
                {/* <div className="row align-items-end justify-content-between">
                    <div className="d-none d-md-block col-md-4">
                        <img className="w-100" src={image1 ? image1 : defaultImage1} alt="" />
                    </div>
                    <div className="col-md-3 center__div">
                        <img className='chefjoy' src={chef} alt="Chef Joy" />
                        <h1 className='mb-lg-5 main-title font-bold text-orange'>{t('product.our_product')}</h1>
                    </div>
                    <div className="d-none d-md-block col-md-4">
                        <img className="w-100" src={image2 ? image2 : defaultImage2} alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    );
}
export default ProductBanner;