import Slider from "react-slick";
import "../../assets/plugins/slick/slick.min.css";
import style from './mainslider.module.scss';
import SliderItem from "./SliderItem";
import ArrowButton from "../utilities/ArrowButton";
const MainSLider = ({ data, arrow, dot }) => {
    const settings = {
        dots: dot ? dot : false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: 'cubic-bezier(0.45,0.05,0.55,0.95)',
        arrows: arrow ? arrow : false,
        prevArrow: <ArrowButton className="prev"/>,
        nextArrow: <ArrowButton className="next" />,
        responsive: [
            {
              breakpoint: 575,
              settings: {
                arrows: false,
              }
            }
          ]
    }
    return (
        <div className={`main-slider ${style.bannerDiv}`}>
            <Slider {...settings}>
                {data.map((banner, index) => (
                    <SliderItem key={index} image={banner.image_source} subheading={banner.sub_heading} description={banner.caption} heading={banner.heading} />
                ))}
            </Slider>
        </div>
    );
}

export default MainSLider;