import style from './mainslider.module.scss';
import parse from 'html-react-parser';

const SliderItem = ({ image, subheading, heading, link, description, productslider }) => {
    return (
        <div className={style.bannerWrap}>
            <div className='banner-home'>
                <div className={style.bannerContent +' '+ (subheading ? style.withHeading : "")} style={{ backgroundImage: `url(${image})` }}>
                    <div className="container w-100">
                        <div className="ps-2 ps-xl-4 col-7 col-md-6">
                            <p className={`${style.bannerSecond} mb-1 mb-lg-2 text-orange secondary-title font-bold`}>{parse(subheading)}</p>
                            <h5 className={`${style.bannerMain} m-0 text-grey extra-big-title`}>{parse(heading)}</h5>
                            {description && <p className={`${style.bannerDesc} mt-1 mt-lg-3 text-grey third-title`}>{description}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderItem;