import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import MainSLider from "../components/MainSlider";

import Zoom from 'react-medium-image-zoom';
import recipes1 from '../assets/demo/recipes-1.webp';
import recipes2 from '../assets/demo/recipes-2.webp';
// import banner1 from '../assets/demo/img-banner.webp';
// import banner1 from '../assets/demo/img-banner.webp';
import banner1 from '../assets/demo/jinyoung-good-food.jpg';
import banner2 from '../assets/demo/jinyoung-seafood.jpg';
import banner3 from '../assets/demo/jinyoung-refinery-fat.jpg';
import banner4 from '../assets/demo/jinyoung-spices-dried-vegetables.jpg';
import banner5 from '../assets/demo/jinyoung-poultry-base.jpg';
import RecipeSLider from "../components/ListSlider";
import HomeAbout from "../components/section/HomeAbout";
import HomeRnd from "../components/section/HomeRnd";
import ArrowButton from "../components/utilities/ArrowButton";
import ProductSlider from "../components/ProductSlider";
import bgRecipe from '../assets/images/home/bg-recipehome.webp';
import i18n from '../i18n';
import { motion } from "framer-motion";
import useFetch from "../customFunction/useFetch"
import { useTranslation } from 'react-i18next';
import PreLoader from "../components/PreLoader";
import { Oval } from 'react-loader-spinner'
import { Helmet } from "react-helmet";

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.45,
            delay: 0.2,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}

const Home = () => {
    const sliderRef = useRef();
    const apiUrl = process.env.REACT_APP_API_URL;
    const currentLang = i18n.language;
    const { data, isPending, error } = useFetch(apiUrl + 'getBanner');
    const { data: dataProduct, isPending: isPendingProduct, error: errorProduct } = useFetch(apiUrl + 'getProductHome');
    const { data: dataCertification, isPending: isPendingCertification, error: errorCertification } = useFetch(apiUrl + 'getCertification', 1000);
    const { data: dataRecipe, isPending: isPendingRecipe, error: errorRecipe } = useFetch(apiUrl + 'getRecipe?limit=6');
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>PT JINYOUNG - Your Best Partner In Food Industry</title>
                <meta property="og:title" content="PT JINYOUNG - Your Best Partner In Food Industry" />
            </Helmet>
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
                className=""
            >
                <div className={isPending ? 'space__wrapper' : ''}></div>
                {
                    data &&
                    <motion.div
                        initial="hidden"
                        animate={isPending ? null : 'visible'}
                        variants={containerVariants}
                    >
                        <MainSLider dot={true} arrow={true} data={data.bannerContent[currentLang]} />
                    </motion.div>
                }
                <HomeAbout />
                <section
                    className="product-landing">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                {
                                    dataProduct &&
                                    <h1 className="title-left-line position-relative text-animate text-uppercase text-orange mb-3 mb-md-5"><span>{t('link.product')}</span></h1>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        dataProduct && <ProductSlider datas={dataProduct.productContent[currentLang]} />
                    }
                </section>
                <HomeRnd />
                <section className="certification-landing">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <h1 className="title-left-line mb-3 text-uppercase text-orange position-relative fadeUp"><span>{t('rnd.certification')}</span></h1>
                                <div className="container">
                                    {dataCertification &&
                                        <div className={`row div__certification ${dataCertification.certificationContent[currentLang].length < 4 ? "justify-content-center" : ""}`}>
                                            {
                                                dataCertification.certificationContent[currentLang].map((item, index) => (
                                                    <div key={index} className="col-6 col-md-3 ">
                                                        <div className='fadeUp'>
                                                            <Zoom>
                                                                <img className='img__certification' src={item.image_source} alt="" />
                                                            </Zoom>
                                                            <h3 className="third-title text-uppercase">{item.name}</h3>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* {
                    dataRecipe &&
                    <section className="linear-top cream" style={{ backgroundImage: `url(` + bgRecipe + `)` }}>
                        <div className="container ">
                            <div className="row justify-content-between">
                                <div className="col-8 col-md-6">
                                    <h2 className="text-animate main-title text-greensage mb-5 text-uppercase"><i className="mx-3 fal fa-book-spells"></i> {t('link.recipe')}</h2>
                                </div>
                                <div className="col-4 col-md-6 justify-content-end d-flex px-md-4">
                                    {
                                        dataRecipe.recipeContent[currentLang].length > 3 &&
                                        <>
                                            <ArrowButton onClick={() => sliderRef.current.handlePrevClick()} className="mx-1 prev" />
                                            <ArrowButton onClick={() => sliderRef.current.handleNextClick()} className="mx-1 next" />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <RecipeSLider ref={sliderRef} data={dataRecipe.recipeContent[currentLang]} />
                        <div className="text-center">
                            <Link to="/recipe">
                                <button className="btn mt-4 mt-lg-5">{t('global.more')}</button>
                            </Link>
                        </div>
                    </section>
                } */}
            </motion.div>
        </>
    );
}

export default Home;