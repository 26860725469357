import { useState } from "react";
const Hamburger = ({handleClick, currentOpen}) => {
    return (
        <div onClick={handleClick} className={`hamburger d-block d-lg-none ${currentOpen ? 'close' : ''}`}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default Hamburger;