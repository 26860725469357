import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from 'react';
import { gsap, Power3, Power0 } from 'gsap';
import parse from 'html-react-parser';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from 'react-i18next';
gsap.registerPlugin(ScrollTrigger);
const SectionDiv = ({ subtitle, title, paragraphTitle, image1, image2, link, children, decorationRight, decorationLeft, squareImage }) => {
    const {t} = useTranslation();

    useEffect(() => {
        let containers = gsap.utils.toArray('.section-div > div');
        let containers2 = gsap.utils.toArray('.section-div2 > div');
        gsap.utils.toArray('.section-div > div, .section-div2 > div').forEach((container, index) => {
            gsap.fromTo(container,
                {
                    opacity: 0,
                    y: 50,
                    // scale: 0.9,
                },
                {
                    duration: 1,
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    scrollTrigger: {
                        trigger: container,
                    }
                }
            )
        })
    }, []);
    return (
        <div className="section-div-wrap">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1 className="title-left-line mb-3 text-uppercase text-orange position-relative"><span>{parse(subtitle)}</span></h1>
                        <div className="div-grid">
                            <div className="col-div-grid">
                                <h2 className="secondary-title-alt-md text-uppercase">{parse(title)}</h2>
                                {paragraphTitle && <p className="pDesc">{paragraphTitle}</p>}
                            </div>
                            <div className="col-div-grid">
                                <img className={`right-factory d-none d-md-block object-cover w-100 ${squareImage ? 'square' : ''}`} src={image1} alt="jinyoung factory" />
                            </div>
                        </div>
                        <div className="row section-div2 mt-5">
                            <div className="col-xl-5 col-md-5">
                                <img className='main-factory w-100 mb-4 mb-md-0' alt="jinyoung factory" src={image2} />
                            </div>
                            <div className="col-xl-7 col-md-7">
                                <div>
                                    <div className="childrenSec mbs-start-m-5px">
                                        {children}
                                        {link &&
                                            <Link to={link}>
                                                <button className="mt-2 btn">{t('global.more')}</button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {decorationLeft && <img className="dekstop-only img-aksen left" src={decorationLeft} />} */}
            {/* {decorationRight && <img className="dekstop-only img-aksen right" src={decorationRight} />} */}
        </div>
    );
}
export default SectionDiv;