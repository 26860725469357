import recipes1 from '../assets/demo/recipes-1.webp';
import recipes2 from '../assets/demo/recipes-2.webp';
import bg from '../assets/images/recipes/bg-recipe.webp';
import logoChef from '../assets/images/recipes/chef-joy-right.webp';
import CardRecipes from '../components/CardRecipes';
import { motion, useInView } from "framer-motion";
import ContainerSlider from '../components/ContainerSlider';
import { Link } from 'react-router-dom';
import useFetch from "../customFunction/useFetch"
import i18n from '../i18n';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.45,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}
const Recipes = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getRecipe');
    const { data: dataCategory, isPending: isPendingCategory, error: errorCategory } = useFetch(apiUrl + 'getRecipeCategory');
    const currentLang = i18n.language;
    const { t } = useTranslation();
    const [dataFeatured, setDataFeatured] = useState(null);
    const [recipes, setRecipes] = useState(null);
    const totalShow = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const rowRef = useRef(null);
    const isInView = useInView(rowRef)
    const [currentType, setCurrentType] = useState(null);
    useEffect(() => {
        if (data) {
            setDataFeatured(() => {
                const dataItems = data.recipeContent[currentLang].filter((dataItem) => {
                    return dataItem.featured === 1
                })
                return dataItems;
            });
            setRecipes(() => {
                const recipeItems = data.recipeContent[currentLang].filter((recipeItem) => {
                    // if (currentType) return (recipeItem.featured === 0 && recipeItem.type === currentType)
                    if (currentType) return (recipeItem.type === currentType)
                    return recipeItem
                })
                return recipeItems;
            });
        }
    }, [data, currentLang, currentType, currentPage]);
    useEffect(() => {
        const handleScroll = () => {
            if (isInView && recipes) {
                if (Math.ceil(recipes.length / totalShow) > currentPage) setCurrentPage(currentPage + 1);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isInView]);
    function handelType(type) {
        setCurrentPage(1)
        setCurrentType(type)
    }
    return (
        <>
            <Helmet>
                <title>PT JINYOUNG - {t('link.recipe')}</title>
                <meta property="og:title" content={'PT JINYOUNG - ' + t('link.recipe')} />
            </Helmet>
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
                className='page__wrapper'
            >
                <section className='recipes'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='recipes__flex d-block d-lg-flex mb-2'>
                                    <div className='col-flex'>
                                        <img width={'140px'} src={logoChef} alt="Chef Joy" />
                                    </div>
                                    <div className='col-flex'>
                                        <h1 className='text-40px text-spacing-min-2px text-orange text-uppercase'>
                                            <span>
                                                {t('recipe.explore')}
                                            </span>
                                        </h1>
                                        <p className='font-semibold text-24px text-uppercase text-darkgreen text-spacing-min-1-2px'>{parse(t('recipe.desc_recipes'))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {dataFeatured && <ContainerSlider arrow={true} data={dataFeatured} />}
                </section>
                <section className='recipes-filter pt-0'>
                    <div className="container">
                        <div className="ul ul__list pt-lg-2 pt-xl-4">
                            <CategorySelect handleClick={() => handelType(null)} className={!currentType ? "active" : ""} title={t('global.all')} />
                            {
                                dataCategory &&
                                dataCategory.recipeCategory[currentLang].map((category, index) => (
                                    <CategorySelect key={index} handleClick={() => handelType(category.id)} className={category.id === currentType ? "active" : ""} title={category.name} />
                                ))
                            }
                        </div>
                        <div className="row">
                            {
                                recipes && recipes.map((recipe, index) => {
                                    if (index < totalShow * currentPage) {
                                        return (
                                            <div key={index} className="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <CardRecipes image={recipe.thumb_source} name={recipe.name} link={'/recipe/' + recipe.seo_url} duration={recipe.duration} />
                                            </div>
                                        )
                                    }
                                }
                                )
                            }
                        </div>
                        {/* <div className="py-3">
                        <ul className="pagination">
                            <PageSelect nav="prev" />
                            <PageSelect page="1" active={true} />
                            <PageSelect page="2" />
                            <PageSelect page="3" />
                            <PageSelect page="4" />
                            <PageSelect nav="next" />
                        </ul>
                    </div> */}
                    </div>
                </section>
                <div ref={rowRef}></div>
            </motion.div>
        </>
    );
}

export default Recipes;
const CategorySelect = ({ link, title, active, handleClick, className }) => {
    return (
        <li onClick={() => handleClick()} className={className}>
            {title}
        </li>
    )
}

const PageSelect = ({ link, page, active, handleClick, nav }) => {
    return (
        <li onClick={() => handleClick} className={active && 'active'}>
            <Link>
                {page}
                {nav === 'prev' ? <i className='nav far fa-chevron-left'></i> : null}
                {nav === 'next' ? <i className='nav far fa-chevron-right'></i> : null}
            </Link>
        </li>
    )
}