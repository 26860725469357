import { Link } from 'react-router-dom';
import image1 from '../../assets/images/home/home-rnd-lab-right.jpg';
import image2 from '../../assets/images/home/home-rnd-lab-left.jpg';
import bgRnd from '../../assets/images/home/bg-rnd.webp';
import SectionDiv from './SectionDiv';
import decoration1 from './assets/decoration-left-powder.webp';
import decoration2 from './assets/decoration-right-seed.webp';
import i18n from '../../i18n';
import parse from 'html-react-parser';
import useFetch from "../../customFunction/useFetch"
import { useTranslation } from 'react-i18next';
const HomeRnd = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getPageContent?key=home_rnd');
    const currentLang = i18n.language;
    const {t} = useTranslation();
    return (
        <>
            {   data && 
                <section id='sectionRnd' className="rnd-landing">
                    <SectionDiv
                        image1={image1}
                        image2={image2}
                        subtitle={data.pageContent[currentLang].name}
                        title={t('')}
                        // title={t('home.rnd.title')}
                        paragraphTitle={data.pageContent[currentLang].heading}
                        decorationLeft={decoration1}
                        decorationRight={decoration2}
                        link={"/rnd"}>
                        {parse(data.pageContent[currentLang].content)}
                        <Link to="/rnd" className='d-none'>
                            <h2 className='third-title text-link'><i className="fal fa-award me-2"></i>{t('home.rnd.certification')}</h2>
                        </Link>
                    </SectionDiv>
                </section>
            }
        </>
    );
}

export default HomeRnd;