import './App.scss';
import Header from './components/Header';
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from './page/Home';
import Footer from './components/Footer';
import Recipe from './page/Recipe';
import Rnd from './page/Rnd';
import Contact from './page/Contact';
import About from './page/About';
import RecipeDetail from './page/RecipeDetail';
import Product from './page/Product';
import { AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import useFetch from './customFunction/useFetch';
import Seo from './components/Seo';
import PreLoader from './components/PreLoader';
import { MutatingDots } from 'react-loader-spinner'
import { motion } from "framer-motion";
import NotFound from './components/NotFound';

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.45,
      delay: 0.2,
      ease: "easeOut",
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.45,
      ease: "easeIn",
    }
  }
}

function App() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { data, isPending, error } = useFetch(apiUrl + 'metadata');
  const [isLoad, setIsLoad] = useState(true);
  const [isWait, setIsWait] = useState(true);
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 3000);
    setTimeout(() => {
      setIsWait(false);
    }, 4500);
  }, []);
  return (
    <div className="App">
      {
        data && <Seo data={data} />
      }
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.key}>
          <Route path='*' element={<NotFound />} />
          <Route path='/' element={<Home />} />
          <Route path='/recipe' element={<Recipe />} />
          <Route path="/recipe/:seo" element={<RecipeDetail />} />
          <Route path='/rnd' element={<Rnd />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/product' element={<Product />} />
        </Routes>
      </AnimatePresence>
      <Footer />
      {
        isWait &&
        <PreLoader className={isLoad ? "show" : ""}>
          <MutatingDots
            height="100"
            width="100"
            color="#5aa18e"
            secondaryColor='#5aa18e'
            radius='12.5'
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </PreLoader>
      }
    </div>
  );
}

export default App;
