import { Link } from "react-router-dom";

const ProductList = ({ index, link, category, subcategory, handleClick, currentSub, linkanchor }) => {
    return (
        <div className="mb-3 product-index">
            <div className="d-flex">
                <div className="listNumber">{index < 10 ? '0' + index : index}</div>
                {category.name}
            </div>
            <ul>
                {
                    subcategory.map((list, idx) => (
                        // <li key={index}><Link to={list.seo}>{list.name}</Link></li>
                        <li onClick={() => handleClick(index - 1, idx, list.id)} className={currentSub === list.id ? 'active' : ''} key={list.id}>
                            <Link to={ `/product` + `?cat=${category.id}&id=${list.id}`}>
                                <span dangerouslySetInnerHTML={{__html: list.name}}></span>
                            </Link>
                            {/* <Link to={subcategory.linkanchor + `#${list.seo}`}>{list.name}</Link> */}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default ProductList;