import { Link } from 'react-router-dom';
import image1 from '../../assets/images/home/home-about-img.jpg';
import image2 from '../../assets/images/home/home-about-collapse.png';
import bgAbout from '../../assets/images/home/bg-about.webp';
import SectionDiv from './SectionDiv';
import decoration1 from './assets/decoration-left-seed.webp';
import decoration2 from './assets/decoration-right-powder.webp';
import { useEffect, useState, useRef } from 'react';
import { gsap, Power3, Power0 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "../../customFunction/useFetch"
import i18n from '../../i18n';
import parse from 'html-react-parser';
gsap.registerPlugin(ScrollTrigger);
const HomeAbout = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getPageContent?key=home_about');
    const { data: imageAbout, isPending: isPendingImage, error: errorImage  } = useFetch(apiUrl + 'getBanner?section=7');
    const currentLang = i18n.language;
    const sectionRef = useRef(null);
    useEffect(() => {

        gsap.fromTo("#sectionAbout", {
            // background: 'linear-gradient(180deg, white, white)',
        },
            {
                // background: 'linear-gradient(180deg, #d7fbb3 -50%, transparent 70%)',
                duration: 1,
                scrollTrigger: {
                    trigger: "#sectionAbout",
                    start: "top center",
                    end: "30% center",
                    scrub: true,
                }
            })

    }, []);
    return (
        <>
            {
                 imageAbout && data && (
                <section id='sectionAbout' ref={sectionRef} className='about-landing'>
                    <SectionDiv
                        image1={image1}
                        image2={imageAbout?.bannerContent[currentLang][0].image_source}
                        squareImage={false}
                        subtitle={data.pageContent[currentLang].name}
                        title={data.pageContent[currentLang].heading}
                        decorationLeft={decoration1}
                        decorationRight={decoration2}
                        link={"/about"} >
                        {parse(data.pageContent[currentLang].content)}
                    </SectionDiv>
                </section>
                )
            }
        </>
    );
}

export default HomeAbout;