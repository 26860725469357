import Slider from "react-slick";
import "../../assets/plugins/slick/slick.min.css";
import './listslider.scss';
import ArrowButton from "../utilities/ArrowButton";
import CardRecipes from "../CardRecipes";
import { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

// forwardRef -> untuk ngatur yg klo yg dikirim prop & ref
const ListSLider = forwardRef((props, ref) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [padding, setPadding] = useState('0px');
  useEffect(() => {
    function containerPadd() {
      var containerWidth = document.querySelector('.container').offsetWidth;
      var documentWidth = document.body.offsetWidth;
      var thePadding = (documentWidth - containerWidth) / 2;
      setPadding(thePadding > 150 ? 150 : thePadding);
    }
    containerPadd();
    window.addEventListener('resize', containerPadd);
  }, [padding]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: props.data.length < 3 ? props.data.length : 3,
    centerPadding: padding + 'px',
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: false,

    // cssEase: 'cubic-bezier(0.45,0.05,0.55,0.95)',
    arrows: props.arrow ? props.arrow : false,
    prevArrow: <ArrowButton className="prev" />,
    nextArrow: <ArrowButton className="next" />,
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: props.data.length < 2 ? props.data.length : 2,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }
  // useImperativeHandle -> untuk handler ref
  useImperativeHandle(ref, () => ({
    handleNextClick() {
      sliderRef.current.slickGoTo(currentSlide + 1)
    },
    handlePrevClick() {
      sliderRef.current.slickGoTo(currentSlide - 1)
    }
  }));
  useEffect(() => {
    gsap.set(".card__recipe", {
      autoAlpha: 0,
      y: 100,
    })
    ScrollTrigger.batch(".card__recipe", {
      start: '-30% bottom',
      // end: 'bottom bottom',
      // scrub: fa,
      onEnter: elements => {
        gsap.to(elements, {
          autoAlpha: 1,
          y: 0,
          // overwrite: true,
          stagger: 0.15
        });
      },
      onLeaveBack: elements => {
        gsap.to(elements, {
          // autoAlpha: 0,
          // y: 100,
          // overwrite: true
          // stagger: 0.15
        });
      },
    });
    const clonedSlide = document.querySelectorAll('.slider__animate .slick-slide:not(.slick-active)');
    clonedSlide.forEach((element, index) => {
      element.addEventListener('click', () => {
      } )
    })
    // ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".card__recipe", {y: 0}));
  }, []);
  return (
    <>
      <Slider ref={sliderRef} className="list-slider slider__animate" {...settings}>
        {props.data.map((recipe, index) => (
          <CardRecipes key={index} image={recipe.image_source} name={recipe.name} link={"/recipe/"+recipe.seo_url} duration={recipe.duration} />
        ))}
      </Slider>
    </>
  );
});

export default ListSLider;