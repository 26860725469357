import { useRef, useState, useEffect } from "react";
import ProductBanner from "../components/ProductBanner";
import ProductFilter from "../components/ProductFilter";
import { motion } from "framer-motion";
import 'react-medium-image-zoom/dist/styles.css';
import useFetch from "../customFunction/useFetch"
import i18n from '../i18n';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.65,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}
const Product = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getProduct');
    const currentLang = i18n.language;
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>PT JINYOUNG - {t('product.product')}</title>
                <meta property="og:title" content={'PT JINYOUNG -' + t('product.product')} />
            </Helmet>
            <motion.div
                className='page__wrapper'
                initial="hidden"
                animate="visible"
                variants={containerVariants}
                exit="exit"
            >
                <ProductBanner />
                <section className="product linear-top">
                    <div className="container">
                        {
                            data && <ProductFilter datas={data.productContent[currentLang]} />
                        }
                    </div>
                </section>
            </motion.div>
        </>
    );
}

export default Product;