import { Link } from 'react-router-dom';
import style from './containerslider.module.scss';
const SliderItem = ({ image, duration, heading, link }) => {
    return (
        <div className={style.bannerWrap} >
            <Link to={link}>
                <div className={style.bannerContent} style={{ backgroundImage: `url(${image})` }}>
                    <div className="col p-3 text-center text-white ">
                        <h5 className={`${style.bannerMain} m-0 secondary-title`}>{heading}</h5>
                        <p>
                            <i className={`${style.icon} me-2 far fa-clock`}></i>
                            {duration}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default SliderItem;