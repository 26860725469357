import image1 from '../assets/images/about/about-1.webp';
import image2 from '../assets/images/about/about-2.jpg';
import image3 from '../assets/images/about/about-3.webp';
import image4 from '../assets/images/about/about-4.webp';
import image6 from '../assets/images/about/factory.jpg';
import ceo from '../assets/images/about/ceo.webp';
import diagram from '../assets/images/about/diagram.webp';
import signature from '../assets/images/about/signature.webp';
import logo from '../assets/images/about/jfood-logo.webp';
import Zoom from 'react-medium-image-zoom';
import HistorySection from '../components/HistorySection';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import { useEffect } from 'react';
import useFetch from "../customFunction/useFetch"
import i18n from '../i18n';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Seo from '../components/Seo';

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.45,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}
const About = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: dataAbout, isPending: isPendingAbout, error: errorAbout } = useFetch(apiUrl + 'getPageContent?key=about_company_overview');
    const { data: dataCeo, isPending: isPendingCeo, error: errorCeo } = useFetch(apiUrl + 'getPageContent?key=about_ceo');
    const { data: dataVision, isPending: isPendingVision, error: errorVision } = useFetch(apiUrl + 'getPageContent?key=about_vision');
    const { data: dataStory, isPending: isPendingStory, error: errorStory } = useFetch(apiUrl + 'getPageContent?key=about_story');
    const currentLang = i18n.language;

    const { t } = useTranslation();
    useEffect(() => {
        ScrollTrigger.batch(".fadeUp", {
            once: true,
            onEnter: elements => {
                gsap.fromTo(elements, {
                    opacity: 0,
                    y: 100,
                }, {
                    opacity: 1,
                    y: 0,
                    stagger: 0.1
                });
            },
        });
        
    }, []);

    return (
        <>
            <Helmet>
                <title>PT JINYOUNG - {t('link.about')}</title>
                <meta property="og:title" content={'PT JINYOUNG - ' + t('link.about')} />

            </Helmet>
            <motion.div
                className='page__wrapper'
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
            >
                <div className={isPendingAbout ? 'space__wrapper' : ''}></div>
                <div className='about' id='companyoverview'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12   ">
                                {dataAbout &&
                                    <>
                                        <h1 className="title-left-line mb-3 text-uppercase text-orange position-relative"><span>{dataAbout.pageContent[currentLang].name}</span></h1>
                                        <div className='row'>
                                            <p className="col-lg-8 short-desc fadeUp">{parse(dataAbout.pageContent[currentLang].heading)}</p>
                                        </div>
                                        <div className="about__overview">
                                            {parse(dataAbout.pageContent[currentLang].content)}
                                        </div>
                                    </>
                                }
                                <div className="collapse__img d-none">
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <Zoom>
                                                <img className='fadeUp' src={image6} />
                                            </Zoom>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <Zoom>
                                                <img className='fadeUp' src={image1} />
                                            </Zoom>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <Zoom>
                                                <img className='fadeUp' src={image4} />
                                            </Zoom>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-ceo' id='ceomessage'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="row">
                                    {
                                        dataCeo &&
                                        <>
                                            <div className="col-md-12">
                                                <h1 className="title-left-line mb-4 text-uppercase text-orange position-relative"><span>{dataCeo.pageContent[currentLang].name}</span></h1>
                                            </div>
                                            <h2 className="mb-3 secondary-title text-greensage text-uppercase fadeUp d-none">{dataCeo.pageContent[currentLang].heading}</h2>
                                            {parse(dataCeo.pageContent[currentLang].content)}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-vision-mission' id='visionmission'>
                    <div className="container">
                        <div className="row justify-content-center">
                            {
                                dataVision &&
                                <>
                                    <div className='col-md-12'>
                                        <h1 className='title-left-line mb-3 text-uppercase text-orange position-relative'><span>{parse(dataVision.pageContent[currentLang].name)}</span></h1>
                                    </div>
                                    <div className="col-md-8 text-left pe-xl-5">
                                        <h2 className='text-greensage secondary-title text-uppercase mb-3 mb-lg-4 fadeUp'>{dataVision.pageContent[currentLang].heading}</h2>
                                        {parse(dataVision.pageContent[currentLang].content)}
                                    </div>
                                </>
                            }
                            <div className="col-md-4 d-flex align-items-center">
                                <img src={diagram} className=" w-100" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-history" id='history'>
                    <div className="container">
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <h1 className='title-left-line mb-5 text-uppercase text-orange position-relative'><span>{t('about.history')}</span></h1>
                                <HistorySection />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <section className='jfood linear-top'>
                    <div className="container">
                        {
                            dataStory &&
                            <>
                                <h5 className='mb-1 secondary-title text-orange text-uppercase text-center font-bold fadeUp'>{parse(dataStory.pageContent[currentLang].name)}</h5>
                                <h2 className='text-greensage secondary-title text-uppercase mb-3 mb-lg-4 text-center fadeUp'>{parse(dataStory.pageContent[currentLang].heading)}</h2>
                                <div className="row justify-content-center fadeUp">
                                    {parse(dataStory.pageContent[currentLang].content)}
                                </div>
                            </>
                        }
                    </div>
                </section> */}
            </motion.div>
        </>
    );
}

export default About;