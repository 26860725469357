import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { motion } from "framer-motion";
import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "../customFunction/useFetch"
import i18n from '../i18n';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.45,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}
const Rnd = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getPageContent?key=rnd');
    const { data: dataCertification, isPending: isPendingCertification, error: errorCertification } = useFetch(apiUrl + 'getCertification', 1000);
    const currentLang = i18n.language;
    const { t } = useTranslation();
    useEffect(() => {
        ScrollTrigger.batch(".fadeUp", {
            once: true,
            onEnter: elements => {
                gsap.fromTo(elements, {
                    opacity: 0,
                    y: 100,
                }, {
                    opacity: 1,
                    y: 0,
                    stagger: 0.1
                });
            },
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>PT JINYOUNG - {t('link.rnd')}</title>
                <meta property="og:title" content={'PT JINYOUNG - ' + t('link.rnd')} />
            </Helmet>
            <motion.div
                className='page__wrapper text-center'
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
            >
                <div className={isPending ? 'space__wrapper' : ''}></div>
                <section className='rnd' id="rnd-center">
                    <div className="container">
                        {
                            data &&
                            <>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h1 className="title-left-line text-left mb-2 text-uppercase text-orange position-relative"><span>{parse(data.pageContent[currentLang].name)}</span></h1>
                                        <p className="font-semibold text-24px text-darkgreen text-uppercase fadeUp">{parse(data.pageContent[currentLang].heading)}</p>
                                    </div>
                                </div>
                                {parse(data.pageContent[currentLang].content)}
                            </>
                        }
                    </div>
                </section>
                <section className="rnd-certification linear-top cream" id="certification">
                    <h1 className="text-orange text-40px text-spacing-min-2px mb-5 px-4 text-uppercase fadeUp">{t('rnd.certification')}</h1>
                    <div className="container">
                        {dataCertification &&
                            <div className={`row div__certification ${dataCertification.certificationContent[currentLang].length < 4 ? "justify-content-center" : ""}`}>
                                {
                                    dataCertification.certificationContent[currentLang].map((item, index) => (
                                        <div key={index} className="col-6 col-md-3 ">
                                            <div className='fadeUp'>
                                                <Zoom>
                                                    <img className='img__certification' src={item.image_source} alt="" />
                                                </Zoom>
                                                <h3 className="third-title text-uppercase">{item.name}</h3>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </section>
            </motion.div>
        </>
    );
}

export default Rnd;