import { Link, redirect,useLocation } from 'react-router-dom';
import './header.scss';
import logo from '../../assets/logo/jinyoung-logo.png';
import { useEffect, useRef, useState } from 'react';
import menus from './menus';
import LinkList from './LinkList';
import Hamburger from './Hamburger';
import ButtonLinkHeader from './ButtonLinkHeader';
import { motion } from 'framer-motion';
const ulVariant = {
    hidden: {
        right: '-150%',
        transition: {
            delay: 0.15,
            duration: 1,
            staggerChildren: 0.1,
        }
    },
    show: {
        right: 0,
        transition: {
            duration: .7,
            staggerChildren: 0.07,
        }
    }
}
const liVariant = {
    hidden: {
        opacity: 0,
        x: 100,
        // y: 20,
    },
    show: {
        opacity: 1,
        x: 0,
        y: 0,
    }
}
const liVariantDekstop = {
    hidden: {
        opacity: 1,
        x: 0,
        y: 0,
    },
    show: {
        opacity: 1,
        x: 0,
        y: 0,
    }
}


const transition = {
    duration: 0.7,
    opacity: {
        duration: .8,
        delay: 0.3
    },
    ease: [0.45, 0.05, 0.55, 0.95],
}
const Header = () => {
    const location = useLocation();
    const headerRef = useRef();
    const [topPosition, setTopPosition] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [openMenu, setOpenMenu] = useState(window.innerWidth > 991 ? true : false);

    useEffect(() => {

        var oldScrollY = window.scrollY;
        function resizeHandler() {
            setWidth(window.innerWidth);
            if(window.innerWidth > 991){
                setOpenMenu(true)
            }else{
                setOpenMenu(false)
            }
        }
        function listenerPosition() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (oldScrollY < window.scrollY) setShowHeader(false)
            else {
                setTopPosition(true)
                setShowHeader(true);
            }
            if (currentScrollPosition <= 30) setTopPosition(true)
            else setTopPosition(false)
            oldScrollY = window.scrollY;
        }
        window.addEventListener('scroll', listenerPosition);
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('scroll', listenerPosition)

        }
    }, [location])

    function handleClick() {
        setOpenMenu(!openMenu)
    }
    if (openMenu && width <= 991) document.body.classList.add('overflow')
    else document.body.classList.remove('overflow')
    var classHeader = [];
    if (showHeader) {
        classHeader.push('sticky')
        if (topPosition) classHeader = []
    }
    else classHeader.push('sticky hide')
    return (
        <header ref={headerRef} className={classHeader}>
            <div className="container">
                <div className='div-logo'>
                    <Link to="/">
                        <img src={logo} alt="Jinyoung Logo" />
                    </Link>
                </div>
                <div
                    className='div-nav'>
                    <ButtonLinkHeader mobileOnly={true} />
                    <Hamburger currentOpen={openMenu} handleClick={handleClick} />
                    <motion.ul
                        variants={width <= 991 ? ulVariant : null}
                        initial="hidden"
                        animate={openMenu ? "show" : "hidden"}
                        className={`nav-list ${openMenu ? 'open' : ''}`}>
                        {menus.map((menu, index) => 
                        (
                            
                            <motion.li
                                // variants={liVariant}
                                variants={width <= 991 ? liVariant : liVariantDekstop}
                                transition={width <= 991 ? { ...transition } : null}
                                key={index}
                            >
                                <LinkList handleClick={handleClick} name={menu.name} keys={menu.key} submenu={menu.children} className={menu.class ? menu.class : ''} link={menu.link} />
                                
                               
                            </motion.li>
                        ))}
                        <li className='button-div'>
                            <ButtonLinkHeader />
                        </li>
                    </motion.ul>
                </div>
            </div>
        </header>
    );
}

export default Header;