import './ingredient.scss';
import parse from 'html-react-parser';
import chef from '../../assets/images/recipes/chef-joy-small.webp'
const Ingredients = ({data}) => {
    return (
        <div className="ingredient__card">
            <div className="__head bg-greenalt">
                <label className="font-bold text-white">INGREDIENTS</label>
                <img src={chef} className='joy__logo' alt="Chef Joy Logo" />
            </div>
            <div className="__body">
                {parse(data)}
            </div>
        </div>
    )
}

export default Ingredients;