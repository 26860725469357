const menus = [
    {
        name: "Home",
        key: "home",
        link: "/"
    },
    {
        name: "About Us",
        key: "about",
        link: "/about",
        children: [
            {
                name: "Company Overview",
                key: "about-submenu-1",
                linkanchor: "companyoverview",
                link: "/about",
            },
            {
                name: "Ceo Message",
                key: "about-submenu-2",
                linkanchor: "ceomessage",
                link: "/about",
            },
            {
                name: "Vision & Mission",
                key: "about-submenu-3",
                linkanchor: "visionmission",
                link: "/about",
            },
            {
                name: "History",
                key: "about-submenu-4",
                linkanchor: "history",
                link: "/about",
            }
        ]
    },
    {
        name: "Product",
        key: "product",
        link: "/product"
    },
    {
        name: "Recipe",
        key: "recipe",
        link: "/recipe"
    },
    {
        name: "R&D",
        key: "rnd",
        link: "/rnd",
        children: [
            {
                name: "R&D Center",
                key: "rnd-submenu-1",
                linkanchor: "rnd-center",
                link: "/rnd",
            },
            {
                name: "Certification",
                key: "rnd-submenu-2",
                linkanchor: "certification",
                link: "/rnd",
            }
        ]
    },
    {
        name: "Contact Us",
        key: "contact",
        link: "/contact",
    },
];
export default menus;