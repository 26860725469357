import Slider from "react-slick";
import "../../assets/plugins/slick/slick.min.css";
import style from './containerslider.module.scss';
import SliderItem from "./SliderItem";
import ArrowButton from "../utilities/ArrowButton";
import { useEffect, useState } from "react";
const ContainerSlider = ({ data, arrow, dot }) => {
    const [padding, setPadding] = useState('0px');
    useEffect(() => {
        function containerPadd() {
            if(data && data.length > 1){
                    var containerWidth = document.querySelector('.container').offsetWidth;
                    var documentWidth = document.body.offsetWidth;
                    var thePadding = (documentWidth - containerWidth) / 1.5;
                    setPadding(thePadding > 150 ? 150 : thePadding);
                    if (documentWidth > 575) {
                        var buttonPrev = document.querySelector('.container-slider .slick-prev').style.left = padding + 'px';
                        var buttonNext = document.querySelector('.container-slider .slick-next').style.right = padding + 'px';
                    }
                    else{
                        var buttonPrev = document.querySelector('.container-slider .slick-prev').style.left =  '20px';
                        var buttonNext = document.querySelector('.container-slider .slick-next').style.right = '20px';
                    }
            }

        }
        containerPadd();
        window.addEventListener('resize', containerPadd);
    }, [padding]);
    const settings = {
        dots: dot ? dot : false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        centerMode: true,
        centerPadding: padding + 'px',
        autoplaySpeed: 4000,
        cssEase: 'cubic-bezier(0.45,0.05,0.55,0.95)',
        arrows: arrow ? arrow : false,
        prevArrow: <ArrowButton className="prev" />,
        nextArrow: <ArrowButton className="next" />,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    // arrows: false,
                }
            }
        ]
    }
    return (
        <div className={`container-slider ${style.bannerDiv}`}>
            <Slider {...settings}>
                {data.map((recipe, index) => (
                    <SliderItem key={index} image={recipe.image_source} link={recipe.seo_url} duration={recipe.duration} heading={recipe.name} />
                ))}
            </Slider>
        </div>
    );
}

export default ContainerSlider;