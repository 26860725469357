import ProductList from './ProductList';
import './productslider.scss';
// import datas from './dataProduct.js';
import { Link } from 'react-router-dom';
import ProductSliderDiv from './ProductSliderDiv';
import { useEffect, useState, useRef } from 'react';
import { gsap, Power3, Power0 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useFetch from "../../customFunction/useFetch"
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
gsap.registerPlugin(ScrollTrigger);

const ProductSlider = ({ datas }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getBanner?section=6');
    const currentLang = i18n.language;
    const [currentData, setCurrentData] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        setCurrentCategory(datas[0].subcategory[0].id);
        setCurrentData(() => {
            if (datas[0].subcategory[0].product.length > 0) return datas[0].subcategory[0].product;
            else {
                var arr = [];
                arr.push(datas[0].subcategory[0]);
                return arr;
            }
        });
    }, [datas]);
    function ForceUpdate(indexParent, indexChild, subcategoryId) {
        if (datas[indexParent].subcategory[indexChild].product.length > 0) setCurrentData(datas[indexParent].subcategory[indexChild].product)
        else {
            var arr = [];
            arr.push(datas[indexParent].subcategory[indexChild]);
            setCurrentData(arr)
        }
        setCurrentCategory(subcategoryId)
    }
    const handleClick = (a, b, c) => ForceUpdate(a, b, c);
    const indexRef = useRef(null);
    useEffect(() => {
        gsap.fromTo(indexRef.current, {
            y: 100,
        }, {
            y: 0,
            scrollTrigger: {
                trigger: indexRef.current,
            }
        })

    }, []);
    return (
        <div className="sliderWrap">
            <div className="sliderDiv d-none">
                { currentData && <ProductSliderDiv data={currentData} /> }
            </div>
            <div ref={indexRef} className="contentDiv d-none">
                {
                    datas.map((data, index) => (
                        <ProductList
                            currentSub={currentCategory}
                            handleClick={handleClick}
                            key={index}
                            category={data.category}
                            subcategory={data.subcategory}
                            index={index + 1}
                        />
                    ))
                }
                <Link to="/product">
                    <button className="btn mt-4">{t('product.all_product')}</button>
                </Link>
            </div>
            <div className='product-wrap'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className='grid-wrap'>
                                <div className='col-grid'>
                                    <div className='product-wrap__top'>
                                        <div className='inner-top'>
                                            <span><span></span></span>
                                            
                                            <span>{data && <span className='font-bold'>{data.bannerContent[currentLang][0].sub_heading}</span>}</span>
                                        </div>
                                    </div>
                                    <div className='product-wrap__img'>
                                        {data && <img src={data.bannerContent[currentLang][0].image_source}></img>}
                                        <p className='mb-0'>
                                        {data && parse(data.bannerContent[currentLang][0].caption)}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-grid'>
                                    <div ref={indexRef} className='product-wrap__list'>
                                        <div className='contentDiv'>
                                            {
                                                datas.map((data, index) => (
                                                    <ProductList
                                                        currentSub={currentCategory}
                                                        handleClick={handleClick}
                                                        key={index}
                                                        category={data.category}
                                                        subcategory={data.subcategory}
                                                        index={index + 1}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default ProductSlider;