import CardHistory from './CardHistory';
import './history.scss';
import i18n from '../../i18n';
import useFetch from "../../customFunction/useFetch"
import { useTranslation } from 'react-i18next';
const HistorySection = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getHistory');
    const currentLang = i18n.language;
    const { t } = useTranslation();
    return (
        <div className="history__div">
            {
                data &&
                data.historyContent[currentLang].map((item, index) => (
                    <CardHistory key={index} title={item.name} content={item.content} />
                ))
            }
        </div>
    );
}

export default HistorySection;