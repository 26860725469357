import { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './history.scss';
import parse from 'html-react-parser';
const CardHistory = ({ title, content }) => {
    const historyRef = useRef(null);
    useEffect(() => {
        // gsap.fromTo(historyRef.current, {
        //     opacity: 0,
        //     y: 50,
        // },
        //     {
        //         stagger: 0.2,
        //         duration: 1,
        //         opacity: 1,
        //         y: 0,
        //         scrollTrigger: {
        //             trigger: historyRef.current,
        //         }
        //     })
    }, []);
    return (
        <div ref={historyRef} className="history__wrap">
            <div className='card-history'>
                <div className="dot active"></div>
                <div className='card-inner'>
                    <div className="card-date">
                        <p>{parse(title)}</p>
                    </div>
                    <div className="card-desc">
                        <p>{parse(content)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardHistory;