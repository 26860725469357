import Map from "../components/Map";
import { useLoadScript } from "@react-google-maps/api";
import bg from '../assets/images/contact/bg-contact.webp';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import gsap from 'gsap';
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import parse from 'html-react-parser';
import useFetch from "../customFunction/useFetch"
import axios from 'axios';
import PreLoader from "../components/PreLoader";
import { Oval } from 'react-loader-spinner'
import { Helmet } from "react-helmet";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.45,
            ease: "easeOut",
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: "easeIn",
        }
    }
}

const Contact = () => {
    const [nameHidden, setNameHidden] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [validName, setValidName] = useState(true);
    const [validCompany, setValidCompany] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validMessage, setValidMessage] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getContact');
    const recaptchaRef = useRef();
    const { t } = useTranslation();
    useEffect(() => {
        setIsSending(false);
        ScrollTrigger.batch(".fade", {
            once: true,
            onEnter: elements => {
                gsap.fromTo(elements, {
                    opacity: 0,
                    scale: .9,
                }, {
                    opacity: 1,
                    scale: 1,
                    stagger: 0.1
                });
            },
        });
        ScrollTrigger.batch(".fadeUp", {
            once: true,
            onEnter: elements => {
                gsap.fromTo(elements, {
                    opacity: 0,
                    y: 30,
                }, {
                    opacity: 1,
                    y: 0,
                    stagger: 0.1
                });
            },
        });
    }, []);
    const { isLoaded } = useLoadScript({
        googleMapApiKey: process.env.REACT_APP_MAPS
    });
    function captchaChange(value) {
        setCaptcha(value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        await recaptchaRef.current.executeAsync();
        const captchaResult = recaptchaRef.current;
        if (
            name === '' ||
            company === '' ||
            email === '' ||
            message === ''
        ) {
            if (!name) setValidName(false)
            if (!company) setValidCompany(false)
            if (!email) setValidEmail(false)
            if (!message) setValidMessage(false)
        }
        else {
            setIsSending(true);
            axios({
                method: "POST",
                url: apiUrl + "contactPost",
                mode: "no-cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                data: {
                    name: name,
                    company: company,
                    email: email,
                    message: message,
                    nameHidden: nameHidden,
                    reCaptcha: captchaResult.getValue()
                }
            })
                // const res = await axios.post(apiUrl + "contactPost", post)
                .then(result => {
                    setName('')
                    setCompany('')
                    setEmail('')
                    setMessage('')
                    setNameHidden('')
                    setIsSending(false)
                    setIsSent(result.data)
                    captchaResult.reset();
                    setTimeout(() => {
                        setIsSent(null)
                    }, 4000);
                })
                .catch(error => {
                    captchaResult.reset();
                    setIsSending(false)
                    setIsSent({ "sent": false })
                    setTimeout(() => {
                        setIsSent(null)
                    }, 4000);
                });
        }

    }
    return (
        <>
            <Helmet>
                <title>PT JINYOUNG - {t('link.contact')}</title>
                <meta property="og:title" content={'PT JINYOUNG - ' + t('link.contact')} />
            </Helmet>
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
                className='page__wrapper'>
                <section className='contact'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <h1 className="title-left-line mb-2 text-uppercase text-orange position-relative"><span>{t("contact.form")}</span></h1>
                                <div className="row">
                                    <div className="col-lg-8"><p className="short-desc text-uppercase">{t("contact.please_use")}</p></div>
                                </div>
                                {
                                    isSent && isSent.sent === true && <FlashMessage text={t('contact.sent')} />
                                }
                                {
                                    isSent && isSent.sent === false && <FlashMessage className="error" text={t('contact.error')} />
                                }
                                <div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="contat-form">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("contact.name")}
                                                        <input placeholder={t("contact.name")}
                                                            maxLength="50"
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            className={`form-control required ${!validName ? `error` : ``}`}
                                                            onChange={event => {
                                                                setName(event.target.value);
                                                                event.target.value === '' ? setValidName(false) : setValidName(true)
                                                            }}
                                                            value={name}
                                                            autoComplete="off" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>
                                                        {t("contact.company")}
                                                        <input
                                                            placeholder={t("contact.company")}
                                                            maxLength="50"
                                                            type="text"
                                                            id="company"
                                                            name="company"
                                                            className={`form-control required ${!validCompany ? `error` : ``}`}
                                                            onChange={event => {
                                                                setCompany(event.target.value);
                                                                event.target.value === '' ? setValidCompany(false) : setValidCompany(true)
                                                            }}
                                                            value={company} autoComplete="off" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>
                                                        Email
                                                        <input
                                                            placeholder="Email"
                                                            id="email"
                                                            maxLength="50"
                                                            type="email"
                                                            name="email"
                                                            className={`form-control required ${!validEmail ? `error` : ``}`}
                                                            onChange={event => {
                                                                setEmail(event.target.value);
                                                                event.target.value === '' ? setValidEmail(false) : setValidEmail(true)
                                                            }}
                                                            value={email}
                                                            autoComplete="off" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>
                                                        {t("contact.inquiry")}
                                                        <textarea
                                                            rows={7}
                                                            placeholder={t("contact.inquiry")}
                                                            id="message"
                                                            type="text"
                                                            name="message"
                                                            className={`form-control required ${!validMessage ? `error` : ``}`}
                                                            autoComplete="off"
                                                            onChange={event => {
                                                                setMessage(event.target.value);
                                                                event.target.value === '' ? setValidMessage(false) : setValidMessage(true)
                                                            }} value={message}></textarea>
                                                    </label>
                                                </div>
                                            </div>
                                            <div id="gositusform" className="form-group" style={{ position: 'fixed', top: '-1000px', left: '-1000px' }}>
                                                <label>
                                                    Name
                                                    <input
                                                        onChange={event => {
                                                            setNameHidden(event.target.value);
                                                        }}
                                                        id="gositusform" value={nameHidden} maxLength="20" type="text" name="gositusform" className="form-control name" autoComplete="off" />
                                                </label>
                                            </div>
                                            <div className="col-12 text-center mt-3">
                                                {
                                                    isSending ?
                                                        <button type="button" disabled className="btn mb-2">{t("contact.send")}</button>
                                                        :
                                                        <button type="submit" className="btn mb-2">{t("contact.send")}</button>
                                                }

                                            </div>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={process.env.REACT_APP_INVISIBLE_SITE_KEY}
                                                size="invisible"
                                            // onChange={captchaChange}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact-information">
                    <h2 className="text-center text-40px text-orange text-spacing-min-2px px-4 text-uppercase"><span>{t("contact.info")}</span></h2>
                    <div className="col">
                        <div className="map__section row mx-0">
                            <div className="col-md-8 px-0 div__map">
                                {/* <div id="map">
                                    {isLoaded ? <Map data={data && data} /> : null}
                                </div> */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.653387570935!2d106.94770711454875!3d-6.438541364751675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69950d44b802eb%3A0x23b7ed09705ccb79!2sJinyoung.%20PT!5e0!3m2!1sid!2sid!4v1681121711915!5m2!1sid!2sid" width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="col-md-4 info">
                                <div>
                                    {
                                        data &&
                                        <>
                                            <p className="third-title text-uppercase font-bold fadeUp">{t("contact.head_office")}</p>
                                            <div className="contact__list fadeUp">
                                                <div className="icon">
                                                    <i className="fas fa-map-marker-alt"></i>
                                                </div>
                                                <p>
                                                    {data && parse(data.address)}
                                                </p>
                                            </div>
                                            <div className="contact__list fadeUp">
                                                <div className="icon">
                                                    <i className="fas fa-phone-alt"></i>
                                                </div>
                                                <a target="_blank" href={data.phone.link}>{data.phone.value}</a>
                                            </div>
                                            <div className="contact__list fadeUp">
                                                <div className="icon">
                                                    <i className="fas fa-fax"></i>
                                                </div>
                                                <a target="_blank" href={data.fax.link}>{data.fax.value}</a>
                                            </div>
                                            <div className="contact__list fadeUp">
                                                <div className="icon">
                                                    <i className="fas fa-envelope"></i>
                                                </div>
                                                <a target="_blank" href={"mailto:" + data.email} >{data.email}</a>
                                            </div>
                                        </>
                                    }
                                </div>
                                <hr />
                                <div>
                                    <p className="third-title text-uppercase font-bold fadeUp">{t("contact.sales_office")}</p>
                                    {
                                        data &&
                                        <>
                                            {
                                                data.branch_address &&
                                                <div className="contact__list fadeUp">
                                                    <div className="icon">
                                                        <i className="fas fa-map-marker-alt"></i>
                                                    </div>
                                                    {data && parse(data.branch_address)}
                                                </div>
                                            }

                                            {
                                                data.branch_phone.value &&
                                                <div className="contact__list fadeUp">
                                                    <div className="icon">
                                                        <i className="fas fa-phone-alt"></i>
                                                    </div>
                                                    <a target="_blank" href={data.branch_phone.link}>{data.branch_phone.value}</a>
                                                </div>
                                            }
                                            {
                                                data.branch_phone2.value &&
                                                <div className="contact__list fadeUp">
                                                    <div className="icon">
                                                        <i className="fas fa-phone-alt"></i>
                                                    </div>
                                                    <a target="_blank" href={data.branch_phone2.link}>{data.branch_phone2.value}</a>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </motion.div>
            <PreLoader className={`transparent  ${isSending ? "show" : ""}`}>
                <Oval
                    height={60}
                    width={60}
                    color="#ff9921"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#ff9921"
                    strokeWidth={2}
                    strokeWidthSecondary={3}
                />
            </PreLoader>
        </>
    );
}

export default Contact;
const FlashMessage = ({ className, text }) => {
    return (
        <div className={`flashmessage ${className}`}>
            {text}
        </div>
    )
}