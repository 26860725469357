import { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "../../assets/plugins/slick/slick.min.css";
import ArrowButton from "../utilities/ArrowButton";
import { motion, useAnimationControls } from "framer-motion";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CrossfadeImage from "react-crossfade-image";
import HTMLReactParser from "html-react-parser";
import parse from 'html-react-parser';
gsap.registerPlugin(ScrollTrigger);

const ProductSliderDiv = ({ data }) => {
    const sliderRef = useRef(null);
    const descDivRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentName, setCurrentName] = useState(data.length > 1 ? data[currentSlide].name : data[0].name);
    const [currentImage, setCurrentImage] = useState(data.length > 1 ? data[currentSlide].image : data[0].image);
    const [currentData, setCurrentData] = useState({});

    const settings = {
        dots: false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        cssEase: 'cubic-bezier(0.45,0.05,0.55,0.95)',
        arrows: false,
        beforeChange: (current, next) => (
            setCurrentSlide(next),
            handleNextClick()
        ),
        prevArrow: <ArrowButton className="prev" />,
        nextArrow: <ArrowButton className="next" />,
    }
    function handleNextClick() {
        sliderRef.current.slickGoTo(currentSlide + 1)
        setCurrentName(data.length > 1 ? data[currentSlide + 1].name : data[0].name);
        setCurrentImage(data.length > 1 ? data[currentSlide + 1].image : data[0].image);
        // setCurrentImage(data[currentSlide + 1].image);
    }
    function handlePrevClick() {
        sliderRef.current.slickGoTo(currentSlide - 1)
        setCurrentName(data.length > 1 ? data[currentSlide - 1].name : data[0].name);
        setCurrentImage(data.length > 1 ? data[currentSlide - 1].image : data[0].image);
        // setCurrentImage(data[currentSlide - 1].image);
    }

    const controlss = useAnimationControls()
    const wait = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    useEffect(() => {
        setCurrentData(data);
        Object.keys(currentData).length !== 0 && setCurrentImage(currentData.length > 1 ? currentData[currentSlide].image : currentData[0].image);
        Object.keys(currentData).length !== 0 && setCurrentName(currentData.length > 1 ? currentData[currentSlide].name : currentData[0].name);
    }, [data])
    return (
        <>
            {Object.keys(currentData).length !== 0 &&
                <div className="product-slider">
                    <Slider className="product-slider-the-slider" ref={sliderRef} {...settings}>
                        {
                            currentData.map((banner, index) => (
                                <div key={index} >
                                        <CrossfadeImage delay={100} duration={1200} containerClass="product-image" alt={banner.name} src={banner.image} />
                                </div>
                            ))}
                    </Slider>
                    <div ref={descDivRef} className='desc-div'>
                        <div className="row justify-content-between align-items-center mb-3">
                            <div className="col-8">
                                <ContentDiv>
                                    <h4 className="m-0 third-title product-title text-uppercase">{currentData.length > 1 ? parse(currentData[currentSlide].name) : parse(currentData[0].name)}</h4>
                                </ContentDiv>
                            </div>
                            {currentData.length > 1 &&
                                <div className="col-4 d-flex justify-content-end">
                                    <ArrowButton onClick={() => handlePrevClick()} className=" no-bg square no-shadow prev me-1" />
                                    <ArrowButton onClick={() => handleNextClick()} className=" no-bg square no-shadow next" />
                                </div>
                            }
                        </div>
                        <ContentDiv delay={0.2}>
                            <p className='product-desc m-0'>{currentData.length > 1 ? currentData[currentSlide].description : currentData[0].description}</p>
                        </ContentDiv>
                    </div>
                </div >
            }
        </>
    );
}

const ContentDiv = ({ children, delay }) => {
    const docVariant = {
        hidden: { y: 30, opacity: 0, scale: 0.95 },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                duration: .5,
                delay: delay ? delay : 0.1,
                ease: [0.45, 0.05, 0.55, 0.95],
                opacity: {
                    delay: 0,
                    duration: .6
                }
            }
        },
        exit: {
            y: 30,
            opacity: 0,
            transition: {
                duration: .6,
                delay: delay ? (delay - 0.1) : 0.2,
                ease: [0.45, 0.05, 0.55, 0.95],
            },
        }
    }
    const wait = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const [content, setContent] = useState(0);
    const controls = useAnimationControls()
    useEffect(() => {
        controls.start('exit');
        async function makeAnimation() {
            await wait(800);
            setContent(children)
            controls.set("hidden");
            controls.start('visible');
        }
        makeAnimation();
    }, [children]);
    return (
        <motion.div
            variants={docVariant}
            initial='hidden'
            animate={controls}>
            {content}
        </motion.div>
    )
}
export default ProductSliderDiv;