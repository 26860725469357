import { Link, useLocation } from "react-router-dom";
import './footer.scss';
import logo from '../../assets/logo/jinyoung-logo.png';
import parse from 'html-react-parser';
import useFetch from "../../customFunction/useFetch"
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
const Footer = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, isPending, error } = useFetch(apiUrl + 'getContact');
    const { t } = useTranslation();
    const footerList = [
        {
            'link' : '/',
            'text' : t("link.home")
        },
        {
            'link' : '/about',
            'text' : t("link.about")
        },
        {
            'link' : '/product',
            'text' : t("link.product")
        },
        {
            'link' : '/recipe',
            'text' : t("link.recipe")
        },
        {
            'link' : '/rnd',
            'text' : t("link.rnd")
        },
        {
            'link' : '/contact',
            'text' : t("link.contact")
        }
    ]
    return (
        <footer>
            <div className="container">
                <div className="row justify-content-lg-between">
                    <div className="order-md-1 order-1 col-lg-4 col-xl-3 mb-3 mb-lg-0">
                        <Link to="/">
                            <img src={logo} alt="Jinyoung Logo" />
                        </Link>
                        {/* <h5 className="small-title text-greensage">Food Ingredients & Herbal Products</h5> */}
                    </div>
                    <div className="order-md-2 order-4 col-md-3 col-lg-2 col-xl-2 mb-4 mb-md-0">
                        <h5 className="text-greensage text-uppercase">MENU</h5>
                        <ul className="list-footer uppercase">
                        {
                        footerList.map((list, index) => (
                            <FooterLink key={index} link={list.link} text={list.text} />
                        ))
                        }
                        </ul>
                    </div>
                    <div className="order-md-3 order-3 col-md-5  col-lg-3 mb-4 mb-md-0">
                        <h5 className="text-greensage text-uppercase">{t("link.contact")}</h5>
                        <ul className="list-footer">
                            {
                                data &&
                                <>
                                    {
                                        data.phone.value &&
                                        <li>
                                            <i className="fas fa-phone-alt fa-fw"></i>
                                            <a href={data.phone.link} target="_blank">{data.phone.value}</a>
                                        </li>
                                    }
                                    {
                                        data.fax.value &&
                                        <li>
                                            <i className="fas fa-fax fa-fw"></i>
                                            <a href={data.fax.link} target="_blank">{data.fax.value}</a>
                                        </li>
                                    }
                                    {
                                        data.email &&
                                        <li>
                                            <i className="fas fa-envelope fa-fw"></i>
                                            <a style={{whiteSpace: 'nowrap'}} href={"mailto:" + data.email} target="_blank">{data.email}</a>
                                        </li>
                                    }
                                </>
                            }
                        </ul>

                    </div>
                    <div className="order-md-4 order-2 col-md-4  col-lg-3 mb-4 mb-md-0">
                        <h5 className="text-greensage text-uppercase">{t("contact.head_office")}</h5>
                        <p>
                            {data && parse(data.address)}
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="copyright d-none">
                © 2023 PT Jinyoung. Website by <a target="_blank" href="https://www.gositus.com/">Gositus</a>
            </div> */}
            <div className="copyright">
                © 2023 PT. JINYOUNG. All Rights Reserved
            </div>
        </footer>
    );
}

export default Footer;

const FooterLink = ({ text, link }) => {
    const location = useLocation();
    const myArray = location.pathname.split("/");
    let current = '/'+myArray[1];

    const handleClickaway = () => {
        window.scrollTo(0, {smooth: true})
    };


    return (
        <li className={current === link ? 'active' : ''}>
            <Link to={link} onClick={() => handleClickaway()} >{text}</Link>
        </li>
    )
}