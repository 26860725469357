import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useMemo} from "react";
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';
import { Element, scroller } from 'react-scroll';


const LinkList = ({ name, link, className, handleClick, keys, submenu }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const myArray = location.pathname.split("/");
    let current = '/'+myArray[1];
    const [hover, setHover] = useState(false);
    const { pathname, hash, key } = useLocation();
    const that = this; 
    const [isHovered, setIsHovered] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [openMenu, setOpenMenu] = useState(window.innerWidth > 991 ? true : false);
    const isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;

    // const ClickHandler = (event) => {
	// 	event.preventDefault();
	// };
    function handleMouseEnter() {
        setIsHovered(true);
    }
    function handleMouseLeave() {
        setIsHovered(false);
    }
    function resizeHandler() {
        setWidth(window.innerWidth);
        if(window.innerWidth > 991){
            setOpenMenu(true)
        }else{
            setOpenMenu(false)
        }
    }

    const handleHideMegaMenu = (sectionName) => {
        setTimeout(()=> {scroller.scrollTo(sectionName, {smooth: true})}, 750);
      }


    const handleClickaway = (sectionName) => {
        window.scrollTo(0, 0);
        setTimeout(()=> {scroller.scrollTo(sectionName, {smooth: true})}, 750);
        // setOpenMenu(!openMenu);
        // setOpenMenu(openMenu => !openMenu);
        // console.log("handleClickaway")
        // event.preventDefault();
    };

    const submenuAnimation = {
        initial: {
            y: 0,
            opacity: 0,
            height: 0,
            visibility:"hidden",
            scale: 1,
        },
        animate: {
            y: 0,
            opacity: 1,
            height: 'auto',
            visibility:"visible",
            scale: 1,
        },
    }
    
    useEffect(() => {
       
      }, []);
    
      

    return (
        <div onClick={handleClick} className={link === current ? 'active' : ''} >
            {(() => {
                    if (isMobile) {
                        return <div className='mobile'>
                            <Link to={link} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {t(`link.${keys}`)}
                                    {(() => {
                                        if (submenu) {
                                            return <div className='sub-menu'>
                                                {(submenu ?? []).map((submenu)=>
                                                    <Link to={link}  onClick={() => handleClickaway(submenu.linkanchor)}   key={submenu.linkanchor} 
                                                >{t(submenu.name)}</Link>
                                                )}
                                            </div>
                                        } else {
                                            return 
                                        }
                                    })()}
                            </Link>
                        </div>
                    } else {
                        return <div className='desktop'>
                            <Link to={link} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {t(`link.${keys}`)}
                                <motion.div
                                    variants={submenuAnimation}
                                    initial="initial"
                                    animate={isHovered ? "animate" : ""}
                                    transition={{
                                        ease: 'easeInOut',
                                        duration: 0.45,
                                    }}
                                    >
                                    {(() => {
                                        if (submenu) {
                                            return <div className='sub-menu'>
                                                {(submenu ?? []).map((submenu)=>
                                                    <Link  onClick={() => handleHideMegaMenu(submenu.linkanchor)}  to={link} key={submenu.linkanchor} 
                                                >{t(submenu.name)}</Link>
                                                )}
                                            </div>
                                        } else {
                                            return 
                                        }
                                    })()}
                                </motion.div>
                            </Link>
                        </div>
                    }
                })()}
  
                {/* <Link to={link} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>{t(`link.${keys}`)}</Link> */}
        </div>
    );
}

export default LinkList;